import React, { useState } from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import YouTube from "react-youtube"
import Modal from "react-modal"

import { ImgRatio } from "../utils/utils"
import { Button } from "../buttons/buttons"

export const Cover = ({
  variant,
  theme,
  breadcrumb,
  img,
  imgMd,
  imgHeight,
  imgOverlay,
  label,
  title,
  titleClassName,
  text,
  btnText,
  btnLink,
  btnClassName,
  contentMaxWidth,
  coverBodyClassName,
  className,
  children,
  type,
  videoSrc,
  audio,
}) => {
  let [modalIsOpen, setIsOpen] = useState(false)
  const modalClick = arg => {
    switch (arg) {
      case "open":
        setIsOpen((modalIsOpen = true))
        break

      case "close":
        setIsOpen((modalIsOpen = false))
        break

      default:
        return null
    }
    setVideoPlay({
      initial: !videoPlay.initial,
      clicked: !videoPlay.clicked,
    })
  }

  const [videoPlay, setVideoPlay] = useState({
    initial: false, // initial video play
    clicked: false, // initial clicked
  })

  const [videoOpts, setVideoOpts] = useState({
    autoPlay: 0,
    controls: 0,
    showBtn: true,
  })

  const handleClick = () => {
    setVideoOpts({
      autoPlay: 1,
      controls: 1,
      showBtn: false,
    })
    setVideoPlay({
      initial: !videoPlay.initial,
      clicked: !videoPlay.clicked,
    })
  }

  const videoOptions = {
    playerVars: {
      autoplay: videoOpts.autoPlay,
      controls: videoOpts.controls,
      play: 0,
      hidden: 0,
      playsInline: 0,
      hidden: 0,
      rel: 0,
      showinfo: 0,
      mute: 0,
      modestbranding: 1,
    },
  }

  //Regex url youtube to id
  let re = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i
  return (
    <div className={`cover cover-${variant} ${theme} ${className}`}>
      <div className={`cover-bg-wrapper`}>
        {type.name === "video" ? (
          <div className="video-background">
            <video
              playsInline
              autoPlay
              muted={audio}
              loop
              className={imgHeight}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
        ) : type.name === "core-embed/youtube" ? (
          <div className="video-background">
            <div
              className={`vid-pulse ${!videoOpts.showBtn && "d-none"}`}
              onClick={handleClick}
            >
              <div className="vid-play">
                <i className="fa fa-play" />
              </div>
            </div>
            <YouTube
              videoId={videoSrc && videoSrc.match(re)[7]}
              opts={videoOptions}
              className={imgHeight}
            />
          </div>
        ) : type.name === "video-popup" ? (
          <div className="cover-video">
            <BackgroundImage
              Tag="div"
              fluid={img}
              className={`cover-bg ${imgHeight}`}
            >
              <div className={`bg-overlay bg-overlay-${imgOverlay}`}></div>
              <ImgRatio />
            </BackgroundImage>
            <div
              className={`vid-pulse`}
              type="button"
              onClick={() => modalClick("open")}
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <div className="vid-play">
                <i className="far fa-play" />
              </div>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => modalClick("close")}
            >
              <div className="video-background">
                <video
                  playsInline
                  autoPlay
                  muted={audio}
                  loop
                  className="w-100"
                >
                  <source src={videoSrc} type="video/mp4" />
                </video>
              </div>
            </Modal>
          </div>
        ) : (
          // <div
          //   className={`cover-bg ${imgHeight}`}
          //   style={{
          //     backgroundImage: `url(${img})`,
          //     backgroundSize: "cover",
          //     backgroundRepeat: "no-repeat",
          //     backgroundPosition: "center",
          //   }}
          // >
          //   <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
          //   <ImgRatio />
          // </div>
          <div>
            {/* Image responsive for MD Up*/}
            <BackgroundImage
              Tag="div"
              fluid={img}
              className={`cover-bg ${imgHeight} d-block d-md-none`}
            >
              <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              <ImgRatio />
            </BackgroundImage>
            <BackgroundImage
              Tag="div"
              fluid={imgMd ? imgMd : img}
              className={`cover-bg ${imgHeight} d-none d-md-block`}
            >
              <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
              <ImgRatio />
            </BackgroundImage>
          </div>
        )}
      </div>
      {breadcrumb && <div className="breadcrumb-wrapper">{breadcrumb}</div>}
      <div className={`cover-body ${videoPlay.clicked && "position-relative"}`}>
        <div className="container">
          <div
            className={`cover-content ${contentMaxWidth}  ${videoPlay.clicked &&
              "pb-0"}`}
          >
            {label && (
              <div className="overflow-hidden">
                <p className="cover-label">{label}</p>
              </div>
            )}
            {title && (
              <h1 className={`cover-title ${titleClassName}`}>{title}</h1>
              // <div className="overflow-hidden">
              // </div>
            )}
            {text && (
              <p
                className="cover-text"
                dangerouslySetInnerHTML={{ __html: text }}
              ></p>
            )}
            {btnLink && (
              <Button variant="link" link={btnLink} className={btnClassName}>
                {btnText}
              </Button>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

Cover.propTypes = {
  variant: PropTypes.oneOf(["basic", "responsive", "fluid"]), // Set cover variant
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  breadcrumb: PropTypes.element, // Set cover breadcrumb (optional)
  img: PropTypes.any, // Set cover background image
  imgHeight: PropTypes.string, // Set .cover-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  title: PropTypes.string, // Set cover title
  btnText: PropTypes.string, // Set cover button text
  btnLink: PropTypes.string, // Set cover button link / href
  btnClassName: PropTypes.string, // Set cover button optional classes
  contentMaxWidth: PropTypes.string, // Set .cover-content max width (with sizing utilities classes (e.g. .mw-md-500px, .mw-100, etc.)
  className: PropTypes.string, // Set cover optional classes
  children: PropTypes.any,
}

Cover.defaultProps = {
  variant: "basic",
  theme: "",
  breadcrumb: null,
  contentMaxWidth: "mw-md-500px",
  imgHeight: "h-400px h-xs-500px h-md-600px",
  imgOverlay: 0,
  titleClassName: "",
  link: "",
  className: "",
  type: "images",
  videoSrc: "",
  audio: false,
}

// Cover with content inside
export const CoverContent = ({
  theme,
  img,
  imgHeight,
  imgOverlay,
  contentMaxWidth,
  className,
  children,
}) => {
  return (
    <div className={`cover ${theme} ${className}`}>
      <div className={`cover-bg-wrapper`}>
        <BackgroundImage
          Tag="div"
          fluid={img}
          className={`cover-bg ${imgHeight}`}
        >
          <div className="cover-body position-relative py-main">
            <div className="container">
              <div className={`cover-content ${contentMaxWidth}`}>
                {children}
              </div>
            </div>
          </div>
          <div className={`bg-overlay bg-overlay-${imgOverlay}`} />
          <ImgRatio />
        </BackgroundImage>
      </div>
    </div>
  )
}

CoverContent.propTypes = {
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  img: PropTypes.any, // Set cover background image
  imgHeight: PropTypes.string, // Set .cover-bg height / ratio with sizing utilities classes (e.g. .h-auto, .h-sm-100px, h-md-vw-100, .h-ratio-1-1, etc.)
  imgOverlay: PropTypes.string, // Set cover overlay %
  contentMaxWidth: PropTypes.string, // Set .cover-content max width (with sizing utilities classes (e.g. .mw-md-500px, .mw-100, etc.)
  className: PropTypes.string, // Set cover optional classes
  children: PropTypes.any,
}

CoverContent.defaultProps = {
  theme: "",
  contentMaxWidth: "mw-md-500px",
  imgHeight: "h-auto",
  imgOverlay: 0,
  className: "",
}
